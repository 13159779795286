import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Faq from '../comps/faqs/faqsItems'
import Footer from '../comps/elements/Footer'
import FasterProcessing from '../comps/cart/FasterProcessing'
// import { onionAddress } from '../constants/constants'
import MetaDecorator from '../helmet/MetaDecorator'
import '../styles/faqs.css'

const Faqs = () => {
    window.scrollTo(0, 0)
    const { t, i18n } = useTranslation()

    return (
        <div className='faqs_container'>

            <MetaDecorator
                title="FAQs"
                description="Frequently asked questions about Powershotz, how to use the site, how to order, and how to download videos and photos."
            />

            <h1 className='faqs_heading'>FAQs</h1>

            <ul className='faqs_main'>
                <Faq
                    question={t('faqs.q1')}
                    answer={i18n.language === 'en'
                        ? <p>Yes! You can sell your videos, photos or anything else that is legal on our sister site.<br /><br />Go to <a href='https://powershopz.com' target="_blank" rel="noreferrer"><b>Powershopz.com</b></a> and check it out!</p>
                        : <p>{t('faqs.a1')}</p>
                    }
                />
                <Faq
                    question={t('faqs.q2')}
                    answer={i18n.language === 'en'
                        ? <div>
                            <p>Here, you cannot use credit cards, Paypal, Venmo, etc. because, &quot;Pornography and other obscene materials (including literature, imagery and other media) depicting nudity or explicitly sexual acts; sites offering any sexually-related services such as prostitution, escorts, pay-per view, adult live chat features; sexually oriented items (e.g., adult toys); adult video stores and sexually oriented massage parlors; gentleman&apos;s clubs, topless bars, and strip clubs; and sexually oriented dating services are <b>STRICTLY FORBIDDEN!</b>&quot;</p>

                            <p>In addition, depicting the use of force in videos is against credit card rules. &quot;Force&quot; is when a performer &quot;looks and sounds&quot; to be in fear, is under excessive duress, is in extreme pain, is intensely struggling while crying or calling for help, is repeatedly saying &quot;no&quot;, or is shaking their head negatively during the scene.</p>

                            <p>Also, as per credit card rules, the performers must not act out or appear to be intoxicated in a sexual context in any clip, video, or image. This includes, but is not limited to alcohol, drugs, and all other substances that cause intoxication or can cause someone to be considered as being under the influence.</p>

                            <p>Obviously, the storylines of the videos on this site are <b>FICTITIOUS</b> and the models are <b>ACTING</b>. We <b>DID NOT</b> film any crimes!</p>

                            <p className='faqs_quote'>We are sorry for the inconvenience, the puritanical nature of our society, and the legislation of your morality...</p>
                        </div>
                        : <p>{t('faqs.a2')}</p>
                    }
                />
                <Faq
                    question={t('faqs.q3')}
                    answer={<div>
                        <p>{t('faqs.a3_1')}</p>
                        <ol>
                            <li>{t('faqs.a3_2')}</li>
                            <li>{t('faqs.a3_3')}</li>
                            <li>{t('faqs.a3_4')}</li>
                            <ul>
                                <li>{t('faqs.a3_5')}</li>
                                <li>{t('faqs.a3_6')}</li>
                            </ul>
                        </ol>
                        <p>{t('faqs.a3_7')}</p>
                    </div>}
                />
                <Faq
                    question={t('faqs.q4')}
                    answer={i18n.language === 'en'
                        ? <>
                            <p>
                                Powershotz videos, clips, and photo sets are ONLY available for
                                sale on this website and{' '}
                                <a href="https://powershopz.com"  ><b>Powershopz.com</b></a>.
                            </p>
                            <p>
                                If you find our videos elsewhere, they are stolen, illegal, and
                                are probably low quality.
                            </p>
                        </>
                        : <p>{t('faqs.a4')}</p>
                    }
                />
                <Faq
                    question={t('faqs.q5')}
                    answer={<div>
                        <p>{t('faqs.a5_1')}</p>
                        <p>
                            <b>{t('faqs.a5_2')}</b>
                        </p>
                        <ol>
                            <li>{t('faqs.a5_3')}</li>
                            <li>{t('faqs.a5_4')}</li>
                            <li>{t('faqs.a5_5')}</li>
                            <li>{t('faqs.a5_6')}</li>
                        </ol>
                        <p>
                            <b>{t('faqs.a5_7')}</b>
                        </p>
                        <ol>
                            <li>
                                <Link to="/contact">{t('nav.contact_us')}</Link>
                            </li>
                        </ol>
                    </div>}
                />
                <Faq
                    question={t('faqs.q6')}
                    answer={<FasterProcessing />}
                />
                <Faq
                    question={t('faqs.q7')}
                    answer={<p>{t('faqs.a7')}</p>}
                />

                <Faq
                    question={t('faqs.q8')}
                    answer={<div>
                        <p>{t('faqs.q8')}</p>
                        <p>
                            <b>{t('faqs.a8_2')}</b>
                        </p>
                        <ol>
                            <li>{t('faqs.a8_3')}</li>
                            <li>{t('faqs.a8_4')}</li>
                        </ol>
                        <p>
                            <b>{t('faqs.a8_5')}</b>
                        </p>
                        <ol>
                            <li>{t('faqs.a8_3')}</li>
                            <li>{t('faqs.a8_6')}</li>
                        </ol>
                    </div>}
                />
                <Faq
                    question={t('faqs.q9')}
                    answer={<>
                        <p>{t('faqs.a9_1')}</p>
                        <p>{t('faqs.a9_2')}</p>

                        <p>
                            <a
                                href='https://www.videolan.org/vlc/'
                                target='_blank'
                                rel="noreferrer"
                            >{t('faqs.a9_3')}</a>.</p>

                    </>}
                />
                <Faq
                    question={t('faqs.q10')}
                    answer={<div>
                        <p>{t('faqs.a10_1')}</p>
                        <p>{t('faqs.a10_2')}</p>
                        <ol>
                            <li>{t('faqs.a10_3')}</li>
                            <li>{t('faqs.a10_4')}</li>
                        </ol>
                        <p>{t('faqs.a10_5')}</p>
                        <ol>
                            <li>{t('faqs.a10_6')}</li>
                            <li>{t('faqs.a10_7')}</li>
                            <li>{t('faqs.a10_8')}</li>
                            <li>{t('faqs.a10_9')}</li>
                            <li>{t('faqs.a10_10')}</li>
                            <li>{t('faqs.a10_11')}</li>
                            <li>{t('faqs.a10_12')}</li>
                            <li>{t('faqs.a10_13')}</li>
                            <li>{t('faqs.a10_14')}</li>
                            <li>
                                <Link to="/contact"><b>{t('faqs.a10_15')}</b></Link>
                            </li>
                        </ol>
                    </div>}
                />
                <Faq
                    question={t('faqs.q11')}
                    answer={<p>{t('faqs.a11')}</p>}
                />

                {/* <Faq
                    question={t('faqs.q12')}
                    answer={<>
                        <p>
                            <a href={onionAddress}>
                                <b>Powershotz Onion</b>
                            </a>{' '}{t('faqs.a12_1')}
                        </p>
                        <p>{t('faqs.a12_2')}</p>
                        <p>
                            {t('faqs.a12_3')}{' '}
                            <a
                                href="https://www.torproject.org"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <b>torproject.org</b>
                                <img
                                    src={"../images/newTabBlue.png"}
                                    className='faqs_image'
                                    alt="tor"
                                    width={10}
                                    height={10}
                                />
                            </a>
                            .
                        </p>

                    </>}
                /> */}

                <Faq
                    question={t('faqs.q13')}
                    answer={<div>
                        <p>{t('faqs.a13_1')}{' '}
                            <Link to="/dvds" ><b>{t('dvd.order_form')}</b></Link>
                        </p>
                        <h3>{t('dvd.us_only')}</h3>
                        <p>{t('faqs.a13_2')}</p>
                        <p>{t('faqs.a13_3')}</p>
                        <p>&emsp;DesignTech<br /> &emsp;PO Box 141<br /> &emsp;Wildwood, MO 63038.</p>
                        <p>{t('faqs.a13_4')}</p>
                    </div>}
                />
                <Faq
                    question={t('faqs.q14')}
                    answer={t('faqs.a14')}
                />
                <Faq
                    question={t('faqs.q15')}
                    answer={t('faqs.a15')}
                />
                <Faq
                    question={t('faqs.q16')}
                    answer={t('faqs.a16')}
                />
                <Faq
                    question={t('faqs.q17')}
                    answer={<div>
                        <p>{t('faqs.a17_1')}  &#x2639;</p>
                        <p>{t('faqs.a17_2')}</p>
                    </div>}
                />
                <Faq
                    question={t('faqs.q18')}
                    answer={t('faqs.a18')}
                />
                <Faq
                    question={t('faqs.q19')}
                    answer={<>
                        <p>{t('faqs.a19_1')}</p>
                        <p>{t('faqs.a19_2')}{' '}<b>{t('faqs.a19_3')}</b></p>
                    </>}
                />
                <Faq
                    question={t('faqs.q20')}
                    answer={<>
                        <p>{t('faqs.a20_1')}</p>
                        <p>{t('faqs.a20_2')}</p>
                    </>}
                />
                <Faq
                    question={t('faqs.q21')}
                    answer={<>
                        <p>
                            <b>{t('faqs.a21_1')}</b> {t('faqs.a21_2')}
                        </p>
                        <p>
                            <Link to="/about"><b>{t('faqs.a21_3')}</b></Link>
                        </p>
                    </>}
                />
                <Faq
                    question={t('faqs.q22')}
                    answer={<p>
                        <Link to="/about" ><b>{t('faqs.a22')}</b></Link>
                    </p>}
                />
                <Faq
                    question={t('faqs.q23')}
                    answer={<>
                        <h3>
                            <strong style={{ color: 'crimson' }}>{t('faqs.a23_1')}</strong>
                        </h3>
                        <p>{t('faqs.a23_2')}</p>
                    </>}
                />

            </ul>
            <Footer />
        </div>
    )
}

export default Faqs