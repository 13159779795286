import DOMPurify from 'dompurify'
import { useTranslation } from 'react-i18next'
import newTabBlue from '../../images/newTabBlue.png'
import PowershopzHeader from './PowershopzHeader'
import { formatPrice } from '../../utils/functions/formatPrice'

const ShippableShopz = ({ shippable }) => {
    const { t } = useTranslation()

    return (
        <div className='cardGridShippable'>

            <PowershopzHeader title={t('home.newest_shippable')} />

            <div className='powershopzSection'>
                {shippable &&
                    shippable.map((product) => {
                        const sale = parseInt(product.sale)
                        const price = product.price
                            ? formatPrice(product.price)
                            : 0
                        const salePrice = product.salePrice
                            ? formatPrice(product.salePrice)
                            : 0
                        const description = DOMPurify.sanitize(product.description, { ALLOWED_TAGS: ['p'] })

                        return product.category !== 'videos' && product.category !== 'photos' && (
                            <div className='latestProductsMap' key={product.id}>
                                <img
                                    src={product.image}
                                    width={100}
                                    alt={product.title}
                                    className='latestImg'
                                />

                                <p>
                                    <span className='productTitle'>{product.title}</span><br />

                                    <span className='productDesc'>{description.slice(0, 340)}...</span>

                                    <br />

                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={`https://powershopz.com/detail/${product.storeName}/${product.title}`}
                                    >
                                        [{t('main.see_more')}]
                                    </a>
                                </p>

                                <p className='priceComponent'>{t('main.only')}{' '}
                                    <span className='price'>
                                        ${sale
                                            ? salePrice
                                            : price}</span>
                                    <span className='sale'>
                                        {sale
                                            ? ` ${t('main.on_sale')}! `
                                            : ' '}</span>
                                    <br />
                                    <a target="_blank"
                                        rel="noopener noreferrer"
                                        className='priceComponent'
                                        href={`https://powershopz.com/${product.storeName}`}>{product.storeName}</a>

                                    <img
                                        src={newTabBlue}
                                        className='copyImg'
                                        width={10}
                                        height={10}
                                        alt=""
                                    />
                                </p>
                                <hr />
                            </div>
                        )
                    })}
            </div>
        </div>
    )
}

export default ShippableShopz