import { useTranslation } from 'react-i18next'
import '../../styles/cart.css'
import { isMobile } from 'react-device-detect'
import { isBanned } from '../order/banned'

const EmailComment = ({
    email,
    setEmail,
    comment,
    setComment,
    setError,
    error,
}) => {
    const { t } = useTranslation()

    const handleEmail = async (email) => {
        setError('')
        await isBanned(email, setEmail)
    }

    return (
        <div className={`cart_mainComp`}>
            <h2 style={styles.heading}><span style={styles.number}>1</span>{t('cart.shipping_address')}</h2>

            <form>
                <input
                    onChange={(e) => {
                        if (e.target.value.includes('@')) {
                            handleEmail(e.target.value)
                        } else {
                            setEmail(e.target.value)
                        }
                    }}
                    name='email'
                    type='email'
                    className={`cart_inputContact`}
                    value={email}
                    required
                    placeholder={t('main.email')}
                    style={error === t('errors.email')
                        ? styles.error
                        : styles.input}
                />
                <label htmlFor='email'>
                    <p className={`emailCommentLabel`}>{t('main.email')}</p>
                </label>

                <input
                    onChange={(e) => {
                        setComment(e.target.value)
                    }}
                    name='comment'
                    type='text'
                    className={`cart_inputContact`}
                    value={comment}
                    placeholder={t('cart.optional')}
                    style={styles.input}
                />
                <label htmlFor='comment'>
                    <p className={`emailCommentLabel`}>{t('cart.comment')}</p>
                </label>
            </form>
        </div>
    )
}

const styles = {
    number: {
        marginRight: 15,
    },
    heading: {
        fontSize: isMobile ? '1.5rem' : '1.8rem',
        marginTop: 0,
    },
    input: {
        width: isMobile ? '95%' : '98%',
    },
    error: {
        width: isMobile ? '95%' : '98%',
        border: '5px solid red',
    }
}

export default EmailComment