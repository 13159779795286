import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import CopyComp from '../CopyComp'
import CoinPicker from './CoinPicker'
import TransactionId from './TransactionId'
import { fetchCoinPrice } from './fetchCoinPrice'
import '../../styles/cart.css'
import { formatPrice } from '../../utils/functions/formatPrice'

const PaymentCrypto = ({
    coinPrice,
    setCoinPrice,
    coin,
    setCoin,
    coinList,
    total,
    txId,
    setTxId,
    dvd,
    setError,
}) => {
    const { t } = useTranslation()

    const [abbr, setAbbr] = useState(coin && coin.value)
    const [src, setSrc] = useState(
        coin &&
        coin.value
        && coin.value.toLowerCase()
    )

    useEffect(() => {
        if (coin) {
            coin.name && fetchCoinPrice(coin.name, setCoinPrice, total)
            if (coin.value === 'USD') {
                setAbbr('')
                setSrc('')
            } else if (coin.value === 'ZELLE') {
                setAbbr('')
                setSrc('zelle')
            } else if (coin.value === 'CASHAPP') {
                setAbbr('')
                setSrc('cashApp')
            } else if (coin.value === 'gumroad') {
                setAbbr('')
                setSrc('cc')
            } else if (coin === 'none') {
                setAbbr('')
                setSrc(null)
                setCoinPrice('')
            } else if (coin && coin.value) {
                setAbbr(coin.value)
                setSrc(coin.value.toLowerCase())
            }
        }
    }, [coin, setCoinPrice, total])

    let coinAddress = coin && coin.address
    if (coin && coin.address === 'alexandra@powershopz.com') {
        coinAddress = 'alexandra@powershotz.com'
    }
    if (coin && coin.value === 'USD') {
        coinAddress = <span>DesignTech<br />PO Box 141<br />Wildwood, MO 63038</span>
    }

    let message = !coin
        ? ''
        : <ol>
            <li>Send your {coin.label} payment (the equivalent of ${formatPrice(total)}) to the address below</li>
            <li>Enter your transaction ID for faster processing</li>
            <li>Come back to this page and press the <b>{dvd ? 'SUBMIT' : 'GET DOWNLOADS'}</b> button<br /><em>If you do not click the button, we will not receive your order!</em></li>
        </ol>

    return coin && (
        <div className={`cart_mainComp`}>
            <h2 className={`payment_heading`}>{t('payment.choose_coin')}</h2>

            <CoinPicker
                coins={coinList}
                coin={coin}
                setCoin={setCoin}
                setError={setError}
            />

            {coin !== 'none' &&
                <span className={`list`}>
                    {message}
                </span>
            }

            <div style={styles.directionsImage}>
                {coin !== 'none' &&
                    <div style={styles.copyCompContainer}>
                        <div style={styles.copyComp}>
                            <p style={styles.direction}> {t('payment.send')}</p>

                            <CopyComp
                                text={`${coinPrice} ${abbr}`}
                                textToCopy={coinPrice}
                            />
                        </div>

                        <div style={styles.copyComp}>
                            <p style={styles.direction}>{t('payment.to')}</p>

                            <CopyComp
                                text={coinAddress}
                                textToCopy={coin.address}
                            />
                        </div>
                    </div>
                }

                {src &&
                    <div>
                        <img
                            src={`https://powershopz.com/altcoin/${src}.png`}
                            width={100}
                            height={100}
                            alt={abbr ? abbr : ''}
                            style={styles.image}
                        />
                    </div>
                }
            </div>

            <TransactionId
                data={coin}
                txId={txId}
                setTxId={setTxId}
            />
        </div >
    )
}

const styles = {
    main: {
        padding: isMobile ? '10px 20px ' : '20px 30px',
        borderTop: '1px solid gray',
        marginTop: 0,
    },
    copyCompContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-evenly',
        minHeight: 120,
    },
    copyComp: {
        display: 'flex',
        flexDirection: 'row',
    },
    direction: {
        fontSize: isMobile ? '1.4rem' : '1.5rem',
        margin: 0,
        marginRight: 10,
        color: 'crimson',
        fontWeight: 'bold'
    },
    directionsImage: {
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: isMobile ? 40 : 10,
        marginBottom: 10,
    },
    image: {
        marginLeft: isMobile ? 0 : 30,
        marginTop: isMobile ? 30 : 'auto'
    }
}

export default PaymentCrypto